import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationA from '../../lotties/03-VIVA_EOY24-Asiento_Tipo-A.json';
import animationB from '../../lotties/03-VIVA_EOY24-Asiento_Tipo-B.json';
import animationC from '../../lotties/03-VIVA_EOY24-Asiento_Tipo-C.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: number;
  customer: Partial<UserInformation>;
}

const Modal3Component: React.FC<Props> = ({ customer }) => {
  const [modalInfo, setModalInfo] = useState({
    number: 1,
    veces: 0,
    animationData: animationA,
  });

  const getSeatsInfo = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const { seats } = backupUser;
      if (seats) {
        let animationData: any = animationA;
        let number = 1;
        const { brand, brand_num } = seats;
        switch (brand) {
          case 'VIP': {
            animationData = animationA;
            number = 1;
            break;
          }
          case 'SpacePlus': {
            animationData = animationB;
            number = 2;
            break;
          }
          case 'Regular': {
            animationData = animationC;
            number = 3;
            break;
          }
          case '': {
            animationData = animationC;
            number = 4;
            break;
          }
        }
        setModalInfo({
          number,
          veces: Number(brand_num),
          animationData,
        });
      }
    }
  };

  useEffect(() => getSeatsInfo(), [customer]);

  return (
    <div className="box-swal box--modal3">
      <div className="row">
        <aside className="box-modal-cols col--color1 col--lottie col-xl-5 col-md-6 col-sm-12 col-12">
          <div className="lottie-player">
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: modalInfo.animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
            />
          </div>
        </aside>
        <div className="box-modal-cols col-vertical-center col-xl-7 col-md-6 col-sm-12 col-12">
          <article className="box-modal-article">
            {modalInfo.number !== 4 && (
              <>
                <p className="text-white h2 font-300">Viajaste</p>
                <h2 className="text-120 text-green300">
                  {modalInfo.veces} veces
                </h2>
              </>
            )}
            {modalInfo.number === 1 && (
              <p className="text-white h2 box-modal-article__priority">
                en asientos VIP Priority
              </p>
            )}
            {modalInfo.number === 2 && (
              <p className="text-white h2 box-modal-article__priority">
                en asientos Space+
              </p>
            )}
            {modalInfo.number === 3 && (
              <p className="text-white h2 box-modal-article__priority">
                en asientos Regulares
              </p>
            )}
            {modalInfo.number === 4 && (
             <>
               <h2 className="text-green300">
                ¿VIP Priority, Space+ o Regular?
              </h2>
             </>
            )}
            {modalInfo.number === 1 && (
              <p className="text-white h3 font-300">
                Eligiendo lo top de lo top.
              </p>
            )}
            {modalInfo.number === 2 && (
              <p className="text-white h3 font-300">¡La comodidad ante todo!</p>
            )}
            {modalInfo.number === 3 && (
              <p className="text-white h3 font-300">¡Comodidad en cualquier parte del avión!</p>
            )}
            {modalInfo.number === 4 && (
              <p className="text-white h3 font-300">
                ¿Cuál será tu asiento favorito el próximo año? ¡Atrévete a probar nuevas formas de viajar!
              </p>
            )}
          </article>
        </div>
      </div>
    </div>
  );
};

export default Modal3Component;
