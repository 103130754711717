import React, {useState, useEffect} from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/Backup01-VIVA_EOY24-KM_Recorridos-DESKTOP.json';
import animationTab from '../../lotties/Backup01-VIVA_EOY24-KM_Recorridos-MOBILE.json';
import animationMobile from '../../lotties/Backup01-VIVA_EOY24-KM_Recorridos-TAB_P.json';
import { UserInformation } from '../../types/vivaaerobus';



interface Props {
  number?: string;
  customer:Partial<UserInformation>;
}
//kilometers_flow, 2 aircarft_type , 3 
const ModalKFComponent: React.FC<Props> = ({ customer , number}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallMobile, setIsSmallMobile] = useState(false);
  

  const getKilometersFlown = (): string => {
    const kilometers = customer?.kilometersFlown;
    return kilometers ? kilometers.toLocaleString() : '0';
  };

  const getFlightsNum = (): string => {
    const flights = customer?.backupUser?.aircraft_flights_num;
    return flights ? flights.toLocaleString() : '0';
  };

  const getNumberForType = (): string => {
    if (number === '1') {
      // Si number es '1', usar directamente esta opción sin validar
      return '1';
    } else if (number === '2') {
      // Validar customer.backupUser?.aircraft_type para casos diferentes a '1'
      return customer.backupUser?.aircraft_type ? '2' : '4';
    } else if (number === '3') {
      return customer.backupUser?.aircraft_type ? '3' : '4';
    }
    return '4'; // Valor predeterminado si no coincide con ninguna opción
  };

  const validatedNumber = getNumberForType();

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        const width = window.innerWidth;
        setIsMobile(width < 768); // Pantallas tipo "tablet"
        setIsSmallMobile(width <= 431); // Pantallas móviles pequeñas
      }
    };

    handleResize(); // Establecer el valor inicial

    window.addEventListener('resize', handleResize);

    // Limpieza al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const animationMap: { [key: number]: { desktop: any; tablet: any; mobile: any } } = {
    1: {
      desktop: animation,
      tablet: animationTab,
      mobile: animationMobile,
    }
  };


 // Seleccionar la animación adecuada
 const animationData =
 validatedNumber && animationMap[parseInt(validatedNumber)]
   ? isSmallMobile
     ? animationMap[parseInt(validatedNumber)].mobile
     : isMobile
     ? animationMap[parseInt(validatedNumber)].tablet
     : animationMap[parseInt(validatedNumber)].desktop
   : animation; // Valor predeterminado

 const animationOptions = {
   loop: false,
   autoplay: true,
   animationData: animationData,
   rendererSettings: {
     preserveAspectRatio: 'xMidYMid MEE',
   },
 };

 // number =  1 = Kilometros flow , 2 = customer.backuser.aircraft_type = A321 , 3 = customer.backuser.aircraft_type = A320 , 4 = customer.backuser.aircraft_type = ´0

 const plain = validatedNumber == '2' ? 'A320' : 'A321';

  return (
    <div className="box-swal box--modalKF" data-info={validatedNumber}>
      <div className="row">
        <div className="box-modal-cols col-12">
          <article className={validatedNumber == '4' ? 'col-lg-6 col-12': ''} data-number={number}>
            {validatedNumber === '1' && (
              <p className="text-white h3">Recorriste un total de</p>
            )}
            {(validatedNumber === '2' || validatedNumber === '3') && (
              <p className="text-white h3">Volaste</p>
            )}
            {validatedNumber === '1' && (
              <h1 className="text-120 text-green300">{getKilometersFlown()}</h1>
            )}
            {(validatedNumber === '2' || number === '3') && (
              <h1 className="text-120 text-green300">{getFlightsNum()} veces</h1>
            )}
            
            {validatedNumber === '1' && (
             <>
              <p className="text-white h3 box-modal-article__priority ">kilómetros</p>
              <p className="text-white h3 font-300">¡De destino en destino!</p>
             </>
            )}
            {(validatedNumber === '2' || validatedNumber === '3') && (
              <>
                <p className="text-white h2 box-modal-article__priority ">en un Airbus {plain}</p>
                <div className="info-row row">
                  <div className="col-lg-5 col-md-12 col-6">
                    <p className="text-white mb-0">Velocidad</p>
                    <p className="text-white h3">870 km/h</p>
                  </div>
                  <div className="col-lg-7 col-md-12 col-6">
                    <p className="text-white mb-0">Altitud máxima</p>
                    <p className="text-white h3">39,800 ft</p>
                  </div>
                    <div className="col-md-12">
                      <p className="text-white mb-0">Capacidad</p>
                      <p className="text-white h3">186 pasajeros</p>
                    </div>
                  
                </div>
              </>
            )}

            {validatedNumber === '4' && (
              <>
                <h1 className="text-white text-64">Confiando en <span className="text-green300">la flota más joven</span> de México.</h1>
                <p className="text-white h3 font-300">Este año hiciste la misma cantidad de vuelos en un Airbus A320 y en un A321.</p>
              </>
            )}
            
            
          </article>
          {validatedNumber === '1' && (
            <div className="lottie-player">
              <Lottie options={animationOptions} />
            </div>
          )}
          {(validatedNumber === '2' || validatedNumber === '4') && (
            <figure className="right-plain">
              <img src="../../../assets/images/elements/plane-tail-simple.png" alt="Plain"/>
            </figure>
          )}
          {validatedNumber === '3' && (
            <figure className="init-plain">
              <img src="../../../assets/images/elements/plane-init.png" alt="Plain"/>
            </figure>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default ModalKFComponent;