import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/11-VIVA_EOY24-Smile Fly_Desktop.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: string;
  customer: Partial<UserInformation>;
}

const Modal11Component: React.FC<Props> = ({ customer }) => {
  const [veces, setVeces] = useState(0);

  const getSmileFlightCount = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const { smile_and_flight_count: flightCount } = backupUser;
      setVeces(Number(flightCount) || 0);
    }
  };

  useEffect(() => getSmileFlightCount(), [customer]);
  return (
    <div className="box-swal box--modal11">
      <div className="row">
        <div className="box-modal-cols col-lg-10 col-12 mx-auto">
          <article className="inner-card">
            <div className="lottie-player">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            </div>
            <div>
              <p className="text-green700 h2">Usaste Smile & Fly</p>
              <h2 className="text-green300 text-64">{`${veces} ${
                veces === 1 ? 'vez' : 'veces'
              }`}</h2>
              <p className="text-green700 h2 mid-title">
                para abordar tus vuelos.
              </p>
              <p className="text-green700 h3 font-300">
                ¡Sonreír te abrió muchas puertas!
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Modal11Component;
