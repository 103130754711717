import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/07-VIVA_EOY24-Maleta.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  customer: Partial<UserInformation>;
}

const Modal7Component: React.FC<Props> = ({ customer }) => {
  const [veces, setVeces] = useState(0);

  const getBaggagesInfo = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const { checked_baggages_count } = backupUser;
      setVeces(Number(checked_baggages_count) || 0);
    }
  };

  useEffect(() => getBaggagesInfo(), [customer]);
  return (
    <div className="box-swal box--modal7">
      <div className="row">
        <aside className="box-modal-cols col--lottie col-md-5 col-12">
          <div className="lottie-player">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
            />
          </div>
        </aside>
        <div className="box-modal-cols col-vertical-center col-md-7 col-12">
          <article className="box-modal-article">
            <p className="text-green700 h3 font-300 initial-title">
              ¡Volando con todo!
            </p>
            <p className="text-green700 h2 font-300">Hiciste</p>
            <h2 className="text-120 text-green300">{`${veces} ${veces === 1 ? 'vuelo' : 'vuelos'
              }`}</h2>
            <p className="text-green700 h3">con maleta documentada</p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Modal7Component;
