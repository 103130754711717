import React from 'react';

type Props = {
  number?: number;
};

const Box3Component: React.FC<Props> = ({ number }) => {
  return (
    <div className="item-box item-box--3">
      <div className="item-box__number">{number}</div>
      <figure>
        <img src="/assets/images/grid/pattern.svg" alt="Casilla 3" />
      </figure>
    </div>
  );
};

export default Box3Component;
