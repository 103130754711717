import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationA from '../../lotties/05-VIVA_EOY24-Canal_Reserva-A.json';
import animationB from '../../lotties/05-VIVA_EOY24-Canal_Reserva-B.json';
import animationC from '../../lotties/05-VIVA_EOY24-Canal_Reserva-D.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: number;
  customer: Partial<UserInformation>;
}

const Modal5Component: React.FC<Props> = ({ customer }) => {
  const [modalInfo, setModalInfo] = useState({
    number: 1,
    veces: 0,
    animationData: animationA,
  });

  const getChannelInfo = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const { channel, channel_num } = backupUser;
      let number = 1;
      let animationData: any = animationA;
      switch (channel) {
        case 'App': {
          number = 1;
          animationData = animationA;
          break;
        }
        case 'Web': {
          number = 2;
          animationData = animationB;
          break;
        }
        case '': {
          number = 4;
          animationData = animationC;
          break;
        }
      }
      setModalInfo({
        number,
        veces: Number(channel_num),
        animationData,
      });
    }
  };

  useEffect(() => getChannelInfo(), [customer]);

  return (
    <div className="box-swal box--modal5" data-info={modalInfo.number}>
      <div className="row">
        <div className="box-modal-cols col-vertical-center col-md-7 col-sm-12 col-12">
          <article className="box-modal-article">
            {(modalInfo.number === 1 || modalInfo.number === 2) && (
              <>
                <p className="text-white h2 font-300">Hiciste</p>
                <h2 className="text-green300 text-96">
                  {modalInfo.veces} reservas
                </h2>
              </>
            )}
            {modalInfo.number === 4 && (
              <p className="text-white h2  font-300">
                Hiciste tus reservas desde diferentes medios,
              </p>
            )}
            {modalInfo.number === 4 && (
              <h2 className="text-white text-64">¡lo importante es viajar!</h2>
            )}
            {modalInfo.number === 1 && (
              <p className="text-white h2 box-modal-article__priority">
                desde la Viva App.
              </p>
            )}
            {modalInfo.number === 2 && (
              <p className="text-white h2 box-modal-article__priority">
                en nuestra página web.
              </p>
            )}
            {modalInfo.number === 3 && (
              <p className="text-white h2 box-modal-article__priority">
                Hiciste la mayoría de tus reservas offline.
              </p>
            )}
            {modalInfo.number === 1 && (
              <p className="text-white h3 font-300">
                ¡Reservando viajes desde cualquier lugar!
              </p>
            )}
            {modalInfo.number === 2 && (
              <p className="text-white h3 font-300">
                Dominando las compras en línea.
              </p>
            )}
            {modalInfo.number === 3 && (
              <p className="text-white h3 font-300">
                ¡Anímate a probar la Viva App para tus siguientes reservas!
              </p>
            )}
          </article>
        </div>
        <aside
          className="box-modal-cols col--lottie col-md-5 col-sm-12 col-12"
          data-lottie={modalInfo.number}
        >
          {(modalInfo.number === 1 ||
            modalInfo.number === 2 ||
            modalInfo.number === 4) && (
            <div className="lottie-player">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: modalInfo.animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            </div>
          )}
          {modalInfo.number === 3 && (
            <figure className="box-modal5__image">
              <img
                src="../../../assets/images/elements/shape-viva.png"
                alt="Reservando viajes"
              />
            </figure>
          )}
        </aside>
      </div>
    </div>
  );
};

export default Modal5Component;
