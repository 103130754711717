import React from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/01-VIVA_EOY24-Ventana.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  customer: Partial<UserInformation>;
}

const Modal1Component: React.FC<Props> = ({ customer }) => {
  const animationOne = {
    loop: false,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const totalSegments = customer.backupUser?.total_segments || '0'

  return (
    <div className="box-swal box--modal1">
      <div className="row">
        <aside className="box-modal-cols col--color1 col--lottie col-md-5 col-sm-12 col-12">
          <div className="lottie-player">
            <Lottie options={animationOne} />
          </div>
        </aside>
        <div className="box-modal-cols col-vertical-center col-md-7 col-sm-12 col-12">
          <article className="box-modal-article">
            <p className="text-white h2">Este año volaste</p>
            <h2 className="text-120 text-green300">
              {totalSegments} veces
            </h2>
            <p className="text-white h3 font-300">¡Disfrutaste del aire como pocos!</p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Modal1Component;
