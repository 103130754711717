import React from "react";
import Lottie from "react-lottie";
import animationLogoViva from '../../lotties/General_Loader.json';


interface LoaderProps {
  isProcessing: boolean;
  title?: string;
  message?: string;
  animation?:string;
}
const LoaderGeneral: React.FC<LoaderProps> = ({ isProcessing , animation }) => {
  if (!isProcessing) {
    return null;
  }


  const animationDefautl = {
    loop: true,
    autoplay: true,
    animationData:  animation || animationLogoViva,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie options={animationDefautl} style={{ width: '20%', height: 'auto', maxWidth: '250px' }}/>
  );
};


export default LoaderGeneral;
