import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import { isMobile } from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sass/App.scss';
import HomeScreen from './pages/Home/home.screen';
import BlankScreen from './pages/Blank/blank.screen';
import LoaderComponent from './components/loader/loader.component';
import EndScreen from './pages/End/end.screen';
import PreventZoom from './components/PreventZoom/preventZoom.component';

setDefaultOptions({ locale: es });

function App() {
  const [isLandscape, setIsLandscape] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [playLoaderAnimation, setPlayLoaderAnimation] = useState(false);

  useEffect(() => {
    // Simulamos el proceso de carga (puede ser una API u otro proceso asíncrono)
    setTimeout(() => {
      setPlayLoaderAnimation(true); // Inicia la animación del loader
      setTimeout(() => {
        setIsLoading(false); // Finaliza el loading y muestra las rutas
      }, 3000); // 3 segundos después de iniciar la animación
    }, 1000); // Simula un delay inicial para el proceso de carga
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Detectar landscape en dispositivos con ancho de pantalla menor o igual a 992px
      const isLandscapeView =
        window.innerWidth > window.innerHeight && window.innerWidth <= 992;
      setIsLandscape(isLandscapeView);
    };
  
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);
  
  if (isMobile && isLandscape) {
    return (
      <div className="orientation-message">
        <article>
          <figure>
            <img
              src="../../assets/images/icons/orientation.png"
              alt="Orientation"
            />
          </figure>
          <p className="text-white text-24">
            Para disfrutar de la experiencia del sitio web, por favor,{' '}
            <strong>gira tu dispositivo a orientación vertical</strong>.
          </p>
        </article>
      </div>
    );
  }

  return (
    <main className="app-main">
      <PreventZoom />
      {isLoading && <LoaderComponent playAnimation={playLoaderAnimation} />}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<BlankScreen />} />
          <Route path="/end" element={<EndScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/:vivaId" element={<HomeScreen />} />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
