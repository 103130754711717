import axios from 'axios';
import qs from 'qs';
import api from './index';
import { IBackupUser, UserInformationResponse } from '../types/vivaaerobus';

const backupDataClient = axios.create({
  baseURL: 'https://h9ero72fzh.execute-api.us-east-1.amazonaws.com/dev',
  headers: {
    'x-api-key': 'HiCIif4m6l3aAT6Mwi6FZEZGa7CLdd97xa8KEm9d',
  },
});

export const getUserInfo = async (vivaId: string) => {
  try {
    const response = await api.get<UserInformationResponse>(
      `/users/information?${qs.stringify({ viva_id: vivaId })}`
    );
    const backupUser = await backupDataClient.get<IBackupUser>(
      `/users/${vivaId}`
    );
    return {
      ...response.data,
      data: {
        ...response.data.data,
        backupUser: backupUser.data,
      },
    };
  } catch (error) {
    console.error(error);
  }
};
