import React from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/Backup03-VIVA_EOY24-Huella_Carbono.json';
import { UserInformation } from '../../types/vivaaerobus';


interface Props {
  number?: number;
  customer:Partial<UserInformation>;
}

const ModalCFComponent: React.FC<Props> = ({customer, number = 1}) => {
  console.log(customer)
  const animationOne = {
    loop: false,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const carbonFootprints = customer?.backupUser?.carbon_footprints || 0;
  const flightText = carbonFootprints === "1" ? '1 vuelo' : `${carbonFootprints} vuelos`;

  return (
    <div className="box-swal box--modalCF">
      <div className="row">
        <div className="box-modal-cols col-xl-11 col-12">
          <section className="card-double-section" data-number={number}>
            <div className="row">
              <article className="col-xl-8 col-md-7 col-12">
                <div>
                  
                  {
                    customer?.backupUser?.carbon_footprints !== '0' ? 
                    (
                      <>
                        <p className="text-green700 h2">Compensaste<br/> tu huella de carbono en:</p>
                        <h2 className="text-120 text-green300">{flightText}</h2>
                      </>
                    )
                    :
                    (
                      <>
                      <p className="text-green700 h2 font-300">Este año,</p>
                      <h2 className="text-120 text-green300">10,803</h2>
                      <p className="text-green700 h2 box-modal-article__priority ">personas compensaron la huella de carbono de sus vuelos</p>
                      <p className="text-green700 h3 font-300">¡En 2025 tú podrías hacerlo también!</p>
                    </>
                    )
                  }         
                </div>
              </article>
              <aside className="col--color2 col-xl-4 col-md-5 col-12">
                <div className="lottie-player">
                  <Lottie options={animationOne} />
                </div>
              </aside>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ModalCFComponent;