import React from 'react';

const TourComponent: React.FC = () => {

  
  return (
    <div className="tour-build">
      <figure>
        <img
            src="/assets/images/icons/hand.svg"
            alt="Tour"
          />
      </figure>
    </div>
  );
};

export default TourComponent;