import React, { useRef, useState } from 'react';
import Lottie from 'react-lottie';
import * as htmlToImage from 'html-to-image';
import ShareComponent from '../ShareComponent/ShareComponent';
import LoaderGeneral from '../loader/loaderVivaLogo.component';
import { UserInformation } from '../../types/vivaaerobus';
import { CustomerProfile } from '../../helpers/helperProfile';
import { useProfileData } from '../../hooks/ProfileUser';


interface Props {
  number?: string;
  customer: Partial<UserInformation>;
  profileUser: CustomerProfile;
}


const Modal12Component: React.FC<Props> = ({customer ,profileUser}) => {

  const shareRef = useRef<HTMLDivElement>(null);
  const shareParent = useRef<HTMLDivElement>(null); 

  const [isProcessing, setIsProcessing] = useState(false);
  const [showShare, setShowShare] = useState(false);
  // Usa el custom hook
  const { currentProfile } = useProfileData(customer, profileUser);

  const animationOne = {
    loop: false,
    autoplay: true,
    animationData: currentProfile.animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleShare = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  
    setIsProcessing(true);
    setShowShare(true);
  
    try {
      if (!shareRef.current) {
        console.error('El nodo de referencia no está disponible.');
        return; 
      }
  
      console.log(shareRef.current.children, "children del nodo en captura");
      await document.fonts.load('10pt "Poppins"');
      await document.fonts.ready;
      // Forzar decodificación previo a la captura buena
      await htmlToImage.toPng(shareRef.current, {}).catch(() => {});
      await htmlToImage.toPng(shareRef.current, {}).catch(() => {});
  
      // Espera un momento antes del intento final
      await new Promise(resolve => setTimeout(resolve, 1000));
  
      
      const dataUrl = await htmlToImage.toPng(shareRef.current);
      const blob = await (await fetch(dataUrl)).blob();
      const fileName = 'MiExperiencia.png';
      const file = new File([blob], fileName, { type: blob.type });
  
      // Detectar si es un entorno móvil
      const isMobile = /Mobi|Android|iPad/i.test(navigator.userAgent);
  
      if (typeof navigator.share === 'function' && 
          navigator.canShare && 
          navigator.canShare({ files: [file] }) && 
          isMobile) {
        // Compartir en móviles que soportan navigator.share
        const shareData = { files: [file] };
        console.log("Intentando compartir estos datos:", shareData);
        console.log("Número de archivos:", shareData.files.length);
        await navigator.share(shareData);
      } else {
        // Descargar en escritorio
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
  
    } catch (error) {
      console.error('Error al compartir:', error);
      alert('Error: No se pudo compartir la experiencia.');
    } finally {
      setIsProcessing(false);
    }
  };

  const validateFlights = customer.backupUser?.total_segments == '0' ? customer.backupUser?.bookings : customer.backupUser?.total_segments

  const userName = customer?.userDetails?.[0]?.firstName

  console.log(customer)

  return (
    <div className="box-swal box--modal12" ref={shareParent}>
      <div className="row">
        <aside className="box-modal-cols col-lottie-end col--color2 col-lg-7 col-12">
          <div className="lottie-player">
            <p className="fade-in">{userName}</p>
            <Lottie options={animationOne} />
          </div>
        </aside>
        <div className="box-modal-cols col--info col-lg-5 col-12 mx-auto">
          <article>    
            <p className="text-white h3 info-title">
              {currentProfile.title}
            </p>
            <div className="info-row row">
              <div className="col-xl-5 col-md-12 col-4">
                <p className="text-white mb-0">Vuelos en 2024</p>
                <p className="text-green300 h3">{validateFlights} vuelos</p>
              </div>

              <div className="col-xl-7 col-md-12 col-4">
                <p className="text-white mb-0">{currentProfile.secondTitle}</p>
                <p className="text-green300 h3">{currentProfile.secondValue}</p>
              </div>

              <div className="col-md-12 col-4">
                <p className="text-white mb-0">{currentProfile.footerTitle}</p>
                <p className="text-green300 h3">
                  {currentProfile.favoriteDestination || 'Datos no disponibles'}
                </p>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button onClick={(e) => handleShare(e)} className="btn btn--type1" style={{zIndex:99999}}>
                ¡Compártelo con tus amigos!
              </button>
            </div>
          </article>
        </div>
      </div>
      <div style={{display: showShare ? 'block' : 'none'}}>
        <ShareComponent data={currentProfile} ref={shareRef}  />
      </div>
      
    {isProcessing && (
      <div className="modal show d-block box-swal loader-general" tabIndex={-1} role="dialog">
        <LoaderGeneral isProcessing={isProcessing}/>
      </div>
    )}
    </div>
  );
};

export default Modal12Component;
