import React from 'react';

const BlankScreen: React.FC = () => {
  return (
    <section className="main-view intro--view">
      <div className="container-fluid">
        <div className="row">
          <aside className="main-view__aside col-lg-4 col-12">
            <figure className="passport">
              <img src="../../../assets/images/elements/passport.svg" alt="Pasaporte"/>
            </figure>
            <figure className="passport-complete">
              <img src="../../../assets/images/elements/passport-complete.svg" alt="Pasaporte"/>
            </figure>
          </aside>
          <section className="main-view__right col-lg-8 col-12">
            <div className="main-article-intro">
              <figure className="base-logo">
                <img src="../../../assets/images/logo/viva-white.svg" alt="Viva"/>
              </figure>
              <article >
                <h1 className="text-white text-64 intro-section__subtitle">Destapa tu <strong className="text-green300">2024</strong></h1>
                <p className="text-white text-20">Para empezar a explorar tu resumen del 2024, ingresa a la liga que enviamos a tu correo electrónico con el asunto <a href="#"><strong>«Tu resumen anual ya está aquí»</strong></a>.</p>
              </article>
              <article className="col-xl-8 col-lg-6 col-12 text-white text-20">
                <p>Recuerda que sólo recibirás un resumen si volaste un mínimo de 3 veces con Viva.</p>

                <p>Si no alcanzaste los 3 vuelos en el último año, te invitamos a volar más con Viva en 2025 ¡y acceder a un resumen hecho solo para ti!</p>
              </article>
            </div>
            <figure className="intro-plain">
              <img src="../../../assets/images/elements/plane-tail.png" alt="Plain"/>
            </figure>
          </section>
        </div>
      </div>
    </section>
  );
};

export default BlankScreen;