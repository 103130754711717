import React from 'react';

type Props = {
  number?: number;
};

const Box11Component: React.FC<Props> = ({ number }) => {
  return (
    <div className="item-box item-box--11">
      <div className="item-box__number">{number}</div>
      <figure className="item-faceid">
        <img src="/assets/images/grid/faceid.svg" alt="FaceId" />
      </figure>
      <figure className="item-smile-logo">
        <img src="/assets/images/grid/smile-and-fly.svg" alt="Smile & Fly" />
      </figure>
    </div>
  );
};

export default Box11Component;
