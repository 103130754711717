import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/10-VIVA_EOY24-TarifaExclusiva.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: string;
  customer: Partial<UserInformation>;
}

const Modal10Component: React.FC<Props> = ({ customer }) => {
  const [tickets, setTickets] = useState(0);

  const getDotersTickets = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const { doters_tickets } = backupUser;
      setTickets(Number(doters_tickets) || 0);
    }
  };

  useEffect(() => getDotersTickets(), [customer]);
  return (
    <div className="box-swal box--modal10">
      <div className="row">
        <div className="box-modal-cols col-12">
          <article>
            <p className="text-white h2 font-300">¡Disfrutaste</p>
            <h2 className="text-green300 text-64">{`${tickets} ${
              tickets === 1 ? 'boleto' : 'boletos'
            }`}</h2>
            <p className="text-white h2 mid-title">
              con Tarifa Exclusiva Doters!
            </p>
            <p className="text-white h2 font-300">
              Y viajaste al mejor precio.
            </p>
            <div className="lottie-player">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Modal10Component;
