import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { Masonry } from 'react-masonry';
import BoxSwal from '../BoxSwal/boxSwal.component';
import Box1Component from '../Boxes/box1.component';
import Box2Component from '../Boxes/box2.component';
import Box3Component from '../Boxes/box3.component';
import Box4Component from '../Boxes/box4.component';
import Box5Component from '../Boxes/box5.component';
import Box6Component from '../Boxes/box6.component';
import Box7Component from '../Boxes/box7.component';
import Box8Component from '../Boxes/box8.component';
import Box9Component from '../Boxes/box9.component';
import Box10Component from '../Boxes/box10.component';
import Box11Component from '../Boxes/box11.component';
import Box12Component from '../Boxes/box12.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Modal1Component from '../Modal/modal1.component';
import { UserInformation } from '../../types/vivaaerobus';
import TourComponent from '../Tour/tour.component';
// import ShareComponent from '../ShareComponent/ShareComponent';
// import { dataFakeShare } from '../ShareComponent/dataFake';

interface GridProps {
  customer: Partial<UserInformation>;
}

const GridComponent: React.FC<GridProps> = ({ customer }) => {
  const [hoveredBoxIndex, setHoveredBoxIndex] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedBox, setSelectedBox] = useState<number | null>(null); // Controla el índice seleccionado
  const [boxHeights, setBoxHeights] = useState<{
    large: number;
    small: number;
  }>({
    large: 550,
    small: 275,
  });

   // Estado para controlar la visibilidad del componente por inactividad
  const [showInactivityComponent, setShowInactivityComponent] = useState(false);

  // Timers y referencias
  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);
  const cooldownTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Constantes de tiempo
  const INACTIVITY_DELAY = 20000; // 10 seg inactivo para mostrar el componente
  const COOLDOWN_PERIOD = 600000; // 10 minutos en ms
  
    // Estado para controlar si estamos en periodo de inactividad o en periodo de descanso
  const [isCooldown, setIsCooldown] = useState(false);
  const tourRef = useRef<HTMLDivElement | null>(null);

  
  useEffect(() => {
    adjustBoxHeights();
    window.addEventListener('resize', adjustBoxHeights);

    // Al montar iniciamos la detección de inactividad (primer ciclo)
    startInactivityCheck();

    // Eventos de interacción del usuario
    const events = ["mousemove", "mousedown", "keydown", "touchstart", "scroll", "click"];
    events.forEach(evt => {
      document.addEventListener(evt, handleUserActivity, true);
    });

    return () => {
      window.removeEventListener('resize', adjustBoxHeights);
      events.forEach(evt => {
        document.removeEventListener(evt, handleUserActivity, true);
      });
    };
  }, []);

  

  const showInactivityComponentRef = useRef(showInactivityComponent);

useEffect(() => {
    showInactivityComponentRef.current = showInactivityComponent;
}, [showInactivityComponent]);

const handleUserActivity = () => {
    console.log("User activity detected, showInactivityComponent (ref):", showInactivityComponentRef.current);
    if (showInactivityComponentRef.current) {
        hideInactivityComponent();
        startCooldown();
    } else if (!isCooldown) {
        resetInactivityTimer();
    }
};

  const startInactivityCheck = () => {
    setIsCooldown(false);
    resetInactivityTimer();
  };

  const resetInactivityTimer = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(() => {
      // Si pasan 5 segundos sin actividad, mostramos el componente
      showInactivityComponentFn();
    }, INACTIVITY_DELAY);
  };

  const showInactivityComponentFn = () => {
    setShowInactivityComponent(true);
  };

  const hideInactivityComponent = () => {
    console.log("Hiding inactivity component");
    setShowInactivityComponent(false);
  
    if (tourRef.current) {
      // Verifica si el elemento sigue en el DOM y lo remueve
      const tourElement = tourRef.current.querySelector('.tour-build');
      if (tourElement) {
        tourElement.remove();
      }
    }
  };

  const startCooldown = () => {
    // Entramos en el periodo de descanso de 10 minutos donde no chequeamos inactividad
    setIsCooldown(true);

    // Limpiar cualquier timer previo
    if (inactivityTimerRef.current) clearTimeout(inactivityTimerRef.current);

    // Iniciar cooldown de 10 minutos
    cooldownTimerRef.current = setTimeout(() => {
      // Pasados los 10 minutos, volvemos a vigilar inactividad
      startInactivityCheck();
    }, COOLDOWN_PERIOD);
  };

  useEffect(() => {
    console.log("showInactivityComponent changed:", showInactivityComponent);
  }, [showInactivityComponent]);

  const adjustBoxHeights = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 431) {
      setBoxHeights({ large: 130, small: 130 });
      setIsMobile(true);
    } else if (windowWidth < 768) {
      setBoxHeights({ large: 220, small: 110 });
      setIsMobile(false);
    } else if (windowWidth < 992) {
      setBoxHeights({ large: 320, small: 160 });
      setIsMobile(false);
    } else if (windowWidth < 1200) {
      setBoxHeights({ large: 400, small: 200 });
      setIsMobile(false);
    } else {
      setBoxHeights({ large: 550, small: 275 });
      setIsMobile(false);
    }
  };

  const getIndexMapping = (): number[] => {
    if (isMobile) {
      // En mobile el grid es lineal
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    } else {
      // En desktop ajusta según el diseño gráfico
      return [0, 1, 3, 2, 4, 5, 6, 7, 8, 9, 10, 11];
    }
  };

  const indexMapping = getIndexMapping();

  useEffect(() => {
    adjustBoxHeights();
    window.addEventListener('resize', adjustBoxHeights);
    return () => window.removeEventListener('resize', adjustBoxHeights);
  }, []);

  const boxes: CSSProperties[] = [
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '59.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '99.9%', height: boxHeights.large },
  ];

  const getBoxComponents = (isMobile: boolean) => {
    if (isMobile) {
      return [
        <Box1Component key="box1" number={1} />,
        <Box2Component key="box2" number={2} />,
        <Box3Component key="box3" number={3} />,
        <Box4Component key="box4" number={4} />,
        <Box5Component key="box5" number={5} />,
        <Box6Component key="box6" number={6} />,
        <Box7Component key="box7" number={7} />,
        <Box8Component key="box8" number={8} />,
        <Box9Component key="box9" number={9} />,
        <Box10Component key="box10" number={10} />,
        <Box11Component key="box11" number={11} />,
        <Box12Component key="box12" number={12} />,
      ];
    } else {
      return [
        <Box1Component key="box1" number={1} />,
        <Box2Component key="box2" number={2} />,
        <Box4Component key="box3" number={4} />,
        <Box3Component key="box4" number={3} />,
        <Box5Component key="box5" number={5} />,
        <Box6Component key="box6" number={6} />,
        <Box7Component key="box7" number={7} />,
        <Box8Component key="box8" number={8} />,
        <Box9Component key="box9" number={9} />,
        <Box10Component key="box10" number={10} />,
        <Box11Component key="box11" number={11} />,
        <Box12Component key="box12" number={12} />,
      ];
    }
  };

  const getBoxClass = (box: CSSProperties): string => {
    const heightClass =
      box.height === boxHeights.large ? 'box-large' : 'box-small';
    const widthPercentage = parseInt(box.width as string, 10);
    const widthClass = `box--${widthPercentage}`;
    return `${heightClass} ${widthClass}`;
  };

  const boxComponents = getBoxComponents(isMobile);

  const handleBoxClick = (visualIndex: number) => {
    console.log(`handleBoxClick(${visualIndex})`);
    const logicalIndex = indexMapping[visualIndex]; // Traduce el índice gráfico a lógico

    if (selectedBox === logicalIndex) {
      console.log('Already selected, reopening modal.');
      // Forzar cierre y reapertura
      setSelectedBox(null); // Resetea el estado brevemente
      setTimeout(() => setSelectedBox(logicalIndex), 0); // Vuelve a seleccionar el índice
    } else {
      console.log('LOGICAL INDEX');
      setSelectedBox(logicalIndex); // Actualiza al nuevo índice lógico
    }
  };

  return (
    <section className="masonry">
      {showInactivityComponent && (
  <div ref={tourRef} key={`tour-${showInactivityComponent}`}>
    <TourComponent />
  </div>
)}
      <div className="container">
        <Masonry>
          {boxes.map((box, index) => (
            <div
              key={index}
              style={box}
              data-id={index}
              className={`box-col ${getBoxClass(box)} ${
                hoveredBoxIndex === index ? 'box--viewing' : ''
              }`}
              onClick={() => handleBoxClick(index)} // Usa el índice gráfico
              onMouseEnter={() => setHoveredBoxIndex(index)}
              onMouseLeave={() => {
                setTimeout(() => {
                  setHoveredBoxIndex(null);
                }, 500);
              }}
            >
              <div className="box">
                <div className="box__inner">
                  {boxComponents[index] || <div>Componente no definido</div>}
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      </div>
      {selectedBox !== null && (
        <BoxSwal key={selectedBox} index={selectedBox} customer={customer} />
      )}
    </section>
  );
};

export default GridComponent;
