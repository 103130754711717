import React from 'react';

type Props = {
  number?: number;
};

const Box12Component: React.FC<Props> = ({ number }) => {
  return (
    <div className="item-box item-box--12" data-number={number}>
      <div className="item-box__number">{number}</div>
      <figure>
        <img
          src="/assets/images/grid/passport.svg"
          alt="Passport"
          className="box--12-desktop"
        />
        <img
          src="/assets/images/grid/ticket.svg"
          alt="Passport"
          className="box--12-mobile"
        />
      </figure>
    </div>
  );
};

export default Box12Component;
