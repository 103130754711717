import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationDesktop from '../../lotties/00-VIVA_EOY24-Loader_Regalo-DESKTOP.json';
import animationTablet from '../../lotties/00-VIVA_EOY24-Loader_Regalo-TABLET_L.json';
import animationPortrait from '../../lotties/00-VIVA_EOY24-Loader_Regalo-TABLET_P.json';
import animationMobile from '../../lotties/00-VIVA_EOY24-Loader_Regalo-MOBILE.json';
import { debounce } from 'lodash';

interface LoaderComponentProps {
  playAnimation: boolean;
}

const LoaderComponent: React.FC<LoaderComponentProps> = ({ playAnimation }) => {
  const [currentAnimation, setCurrentAnimation] = useState(() => {
    const width = typeof window !== 'undefined' ? window.innerWidth : 0;

    if (width > 1024) return animationDesktop;
    if (width > 821) return animationTablet;
    if (width > 431) return animationPortrait;
    return animationMobile;
  });

  const animationOne = {
    loop: false,
    autoplay: playAnimation,
    animationData: currentAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      renderer: 'canvas', // Probar esta opción si tu implementación lo permite
    },
  };

  const updateAnimation = () => {
    if (typeof window === 'undefined') return;

    const width = window.innerWidth;

    if (width > 1024) { 
      setCurrentAnimation(animationDesktop);
    } else if (width > 821) {
      setCurrentAnimation(animationTablet);
    } else if (width > 431) {
      setCurrentAnimation(animationPortrait);
    } else {
      setCurrentAnimation(animationMobile);
    }
  };

  useEffect(() => {
    const debouncedUpdate = debounce(updateAnimation, 300);
    updateAnimation();
    window.addEventListener('resize', debouncedUpdate);
  
    return () => {
      window.removeEventListener('resize', debouncedUpdate);
    };
  }, []);

  return (
    <>
      <div className="loader-element">
        <div className="lottie-player">
          <Lottie options={animationOne} />
        </div>
      </div>
    </>
  );
};

export default LoaderComponent;
