/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Modal1Component from '../Modal/modal1.component';
import Modal2Component from '../Modal/modal2.component';
import Modal3Component from '../Modal/modal3.component';
import Modal4Component from '../Modal/modal4.component';
import Modal5Component from '../Modal/modal5.component';
import Modal6Component from '../Modal/modal6.component';
import Modal7Component from '../Modal/modal7.component';
import Modal8Component from '../Modal/modal8.component';
import Modal9Component from '../Modal/modal9.component';
import Modal10Component from '../Modal/modal10.component';
import Modal11Component from '../Modal/modal11.component';
import Modal12Component from '../Modal/modal12.component';
import ModalKFComponent from '../Modal/modal_kilometers_flown.component';
import ModalCFComponent from '../Modal/modal_carbon_footprints.component';
import ModalTFComponent from '../Modal/modal_time_flow.component';
import ModalBNComponent from '../Modal/modal_bundle_name.component';
import ModalPBComponent from '../Modal/modal_promotional_bookings.component';
import { CustomerProfileState, userFlags, UserInformation } from '../../types/vivaaerobus';
import helper from '../../helpers/helper';
import helperProfile from '../../helpers/helperProfile';

interface BoxSwalProps {
  index: number;
  customer: Partial<UserInformation>;
}
const backupModals: ((customer: Partial<UserInformation>) => JSX.Element)[] = [
  (customer: Partial<UserInformation>) => <ModalKFComponent customer={customer} number="1" />, // kilometers_flow
  (customer: Partial<UserInformation>) => <ModalKFComponent customer={customer} number="2" />, // aircraft_type
  (customer: Partial<UserInformation>) => <ModalTFComponent customer={customer} />,           // Time flow
  (customer: Partial<UserInformation>) => <ModalBNComponent customer={customer} />,           // Bundle name
  (customer: Partial<UserInformation>) => <ModalPBComponent customer={customer} />,           // Promotional booking
  (customer: Partial<UserInformation>) => <ModalCFComponent customer={customer} />,           // Carbon Footprints
];

const getBackupModal = (index: number, customer: Partial<UserInformation>): JSX.Element => {
  return backupModals[index]?.(customer) ?? <></>;
};

const getModalWithBackup = (flags: userFlags, customer: Partial<UserInformation>): JSX.Element[] => {
  let backupIndex = 0;
  return [
    flags.membershipInfo
      ? <Modal6Component customer={customer} />
      : backupModals[backupIndex++](customer), // kilómetros_flow

    flags.baggagesInfo
      ? <Modal7Component customer={customer} />
      : backupModals[backupIndex++](customer), // aircraft_type

    flags.vivaFanInfo
      ? <Modal8Component customer={customer} />
      : backupModals[backupIndex++](customer), // Time flow

    flags.cobrandCardInfo
      ? <Modal9Component customer={customer} />
      : backupModals[backupIndex++](customer), // Bundle name

    flags.dotersTickets
      ? <Modal10Component customer={customer} />
      : backupModals[backupIndex++](customer), // Promotional booking

    flags.smileFlightCount
      ? <Modal11Component customer={customer} />
      : backupModals[backupIndex++](customer), // Carbon Footprints
  ];
};



const BoxSwal: React.FC<BoxSwalProps> = ({ index, customer }) => {
  const [show, setShow] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(index);
  
  const [userFlags, setUserFlags] = useState<userFlags>({
    membershipInfo: false,
    baggagesInfo: false,
    vivaFanInfo: false,
    cobrandCardInfo: false,
    dotersTickets: false,
    smileFlightCount: false,
  });

  const [customerProfileState, setCustomerProfileState] = useState<CustomerProfileState>({
    profile: null,
    isLoading: true,
  });

  const timerRef = useRef<number | null>(null);
  const progressBarRef = useRef<HTMLDivElement | null>(null);

  const [modals, setModals] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const flags = helper.getCustomerFlags(customer);
    setUserFlags(flags);
      
    const profilesUser = helperProfile.getCustomerProfile(customer);
    setCustomerProfileState({
      profile: profilesUser,
      isLoading: false,
    });
  
    // Aquí se inicializan los modales del 1 al 5, el 11 con backup, y el 12 al final
    const modalList = [
      <Modal1Component key="modal1" customer={customer} />,
      <Modal2Component key="modal2" customer={customer} />,
      <Modal3Component key="modal3" customer={customer} />,
      <Modal4Component key="modal4" customer={customer} />,
      <Modal5Component key="modal5" customer={customer} />,
      ...getModalWithBackup(flags, customer).map((modal, index) =>
        React.cloneElement(modal, { key: `backupModal-${index}` })
      ),
      // <Modal12Component key="modal12" customer={customer} profileUser={customerProfileState.profile} />
    ];
        // Agregar Modal12 solo si el perfil está disponible
    if (profilesUser) {
      modalList.push(
        <Modal12Component key="modal12" customer={customer} profileUser={profilesUser} />
      );
    }
    setModals(modalList);

  }, [customer]);
  
  const startProgressBar = () => {
    if (progressBarRef.current) {
      const progressBar = progressBarRef.current;
      progressBar.style.transition = 'none';
      progressBar.style.width = '0%';

      progressBar.getBoundingClientRect();

      setTimeout(() => {
        progressBar.style.transition = 'width 10s linear';
        progressBar.style.width = '100%';
      }, 0);

      let startTime: number | null = null;
      const currentUrl = window.location.href;
      const duration = currentUrl.includes("localhost:3000") ? 1000000 : 10000;
  

      const step = (timestamp: number) => {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;

        if (elapsed >= duration) {
          if (currentIndex < modals.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
          } else {
            handleClose();
          }
        } else {
          timerRef.current = requestAnimationFrame(step);
        }
      };

      timerRef.current = requestAnimationFrame(step);
    }
  };

  const clearAllTimers = () => {
    if (timerRef.current !== null) {
      cancelAnimationFrame(timerRef.current);
      timerRef.current = null;
    }
  };

  useEffect(() => {
    if (modals.length === 0) return; // Esperar a que modals se haya llenado
  
    clearAllTimers();
    startProgressBar();
  
    return () => {
      clearAllTimers();
    };
  }, [currentIndex, modals]); 

  const handlePrev = () => {
    if (currentIndex > 0) {
      clearAllTimers();
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < modals.length - 1) {
      clearAllTimers();
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleClose = () => {
    clearAllTimers();
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={true}
      centered
      dialogClassName="swal-instagram-container"
    >
      <Modal.Body>
        <section>
          <div className="stepper" data-index={currentIndex}>
            {modals.map((_, visualIndex) => (
              <div
                key={visualIndex}
                className={`step ${
                  visualIndex < currentIndex ? 'viewed' : ''
                } ${visualIndex === currentIndex ? 'active' : ''}`}
              >
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    ref={visualIndex === currentIndex ? progressBarRef : null}
                  ></div>
                </div>
              </div>
            ))}
          </div>
          {modals[currentIndex]}
          <div className="step-change">
            <button
              id="prevButton"
              style={{ marginRight: '10px' }}
              disabled={currentIndex <= 0}
              onClick={handlePrev}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <button
              id="nextButton"
              style={{ marginRight: '10px' }}
              disabled={currentIndex >= modals.length - 1}
              onClick={handleNext}
            >
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          <button id="closeButton" onClick={handleClose}>
            <i className="fa-solid fa-arrow-left"></i> Regresar
          </button>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default BoxSwal;
