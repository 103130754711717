import { useMemo } from 'react';
import animationA from '../lotties/12-VIVA_Perfil_VarianteA.json';
import animationB from '../lotties/12-VIVA_Perfil_VarianteB.json';
import animationC from '../lotties/12-VIVA_Perfil_VarianteC.json';
import animationD from '../lotties/12-VIVA_Perfil_VarianteD.json';
import animationE from '../lotties/12-VIVA_Perfil_VarianteE.json';
import { CustomerProfile } from '../helpers/helperProfile';
import { ProfileBase, UserInformation } from '../types/vivaaerobus';

export const useProfileData = (
  customer: Partial<UserInformation>,
  profileUser: CustomerProfile
) => {
  const profileData: Record<CustomerProfile, ProfileBase> = useMemo(
    () => ({
      [CustomerProfile.TopTraveler]: {
        animation: animationA,
        profileImage:'/assets/images/profiles/TopViajero.png',
        title: "¡Eres una de las personas que más veces voló con Viva este año!",
        titleShare: "¡Soy una de las personas que más veces voló con Viva este año!",
        flightTitle:"Vuelos en 2024",
        flightValue: `${customer.backupUser?.total_segments === '0'
        ? Number(customer.backupUser?.bookings).toLocaleString()
        : Number(customer.backupUser?.total_segments).toLocaleString()
        } vuelos`,
        secondTitle: "Kilómetros recorridos",
        secondValue: Number(customer.backupUser?.kilometers_flown).toLocaleString() || "000,000",
        secondTitleShare: "Kilómetros recorridos",
        secondTitleValueShare: Number(customer.backupUser?.kilometers_flown).toLocaleString() || "000,000",
        footerTitle: "Destino favorito",
        favoriteDestination: customer.backupUser?.destinations[0]?.name || "Destino no disponible",
      },
      [CustomerProfile.VIP]: {
        animation: animationB,
        profileImage:'/assets/images/profiles/VIP.png',
        title: "Hiciste la mayoría de tus vuelos en asientos VIP Priority.",
        titleShare: "Hice la mayoría de mis vuelos en asientos VIP Priority.",
        flightTitle:"Vuelos en 2024",
        flightValue: `${customer.backupUser?.total_segments === '0'
        ? Number(customer.backupUser?.bookings).toLocaleString()
        : Number(customer.backupUser?.total_segments).toLocaleString()
        } vuelos`,
        secondTitle: "Vuelos con VIP priority",
        secondValue: `${customer.backupUser?.promotional_bookings === '0'
        ? Number(customer.backupUser?.bookings).toLocaleString()
        : Number(customer.backupUser?.promotional_bookings).toLocaleString() 
        } vuelos`,
        secondTitleShare: "Vuelos con VIP priority",
        secondTitleValueShare: `${customer.backupUser?.promotional_bookings === '0'
        ? Number(customer.backupUser?.bookings).toLocaleString()
        : Number(customer.backupUser?.promotional_bookings).toLocaleString() 
        } vuelos`,
        footerTitle: "Destino favorito",
        favoriteDestination: customer.backupUser?.destinations[0]?.name || "Destino no disponible",
      },
      [CustomerProfile.OnTheGo]: {
        animation: animationC,
        profileImage:'/assets/images/profiles/OnTheGo.png',
        title: "Hiciste la mayoría de tus reservas a través de la Viva App.",
        titleShare: "Hice la mayoría de mis reservas a través de la Viva App. ",
        flightTitle: "Vuelos en 2024",
        flightValue: `${customer.backupUser?.total_segments === '0'
        ? Number(customer.backupUser?.bookings).toLocaleString()
        : Number(customer.backupUser?.total_segments).toLocaleString()
        } vuelos`,
        secondTitle: "Kilómetros recorridos",
        secondValue: Number(customer.backupUser?.kilometers_flown).toLocaleString() || "000,000",
        secondTitleShare: "Kilómetros recorridos",
        secondTitleValueShare: Number(customer.backupUser?.kilometers_flown).toLocaleString() || "000,000",
        footerTitle: "Reservas por Viva App",
        favoriteDestination: `${customer.backupUser?.channel_num} reservas`,
      },
      [CustomerProfile.Doters]: {
        animation: animationD,
        profileImage:'/assets/images/profiles/Doters.png',
        title: "Te la viviste ganando Puntos Doters con tus vuelos.",
        titleShare: "Este año me la viví ganando Puntos Doters con mis vuelos. ",
        flightTitle:"Vuelos en 2024",
        flightValue: `${customer.backupUser?.total_segments === '0'
        ? Number(customer.backupUser?.bookings).toLocaleString()
        : Number(customer.backupUser?.total_segments).toLocaleString()
        } vuelos`,
        secondTitle: "Puntos Doters",
        secondValue: Number(customer.backupUser?.benefits_used_doters_points_acumulation).toLocaleString() || "000,000",
        secondTitleShare: "Puntos Doters",
        secondTitleValueShare:  Number(customer.backupUser?.benefits_used_doters_points_acumulation).toLocaleString() || "000,000",
        footerTitle: "Destino favorito",
        favoriteDestination: customer.backupUser?.destinations[0]?.name || "Destino no disponible",
      },
      [CustomerProfile.Aficionado]: {
        animation: animationE,
        profileImage:'/assets/images/profiles/Aficionado.png',
        title: "¡Viajaste con Viva y disfrutaste del aire como pocos!",
        titleShare: "¡Viajé con Viva y disfruté del aire como pocos!",
        flightTitle:"Vuelos en 2024",
        flightValue: `${customer.backupUser?.total_segments === '0'
        ? Number(customer.backupUser?.bookings).toLocaleString()
        : Number(customer.backupUser?.total_segments).toLocaleString()
        } vuelos`,
        secondTitle: "Kilómetros recorridos",
        secondValue: Number(customer.backupUser?.kilometers_flown).toLocaleString() || "000,000",
        secondTitleShare: "Kilómetros recorridos",
        secondTitleValueShare: Number(customer.backupUser?.kilometers_flown).toLocaleString() || "000,000",
        footerTitle: "Destino favorito",
        favoriteDestination: customer.backupUser?.destinations[0]?.name || "Destino no disponible",
      },
    }),
    [customer]
  );

  const currentProfile = profileData[profileUser];

  return { profileData, currentProfile };
};
