import React, { useEffect, useState } from 'react';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: string;
  customer: Partial<UserInformation>;
}

const Modal9Component: React.FC<Props> = ({ customer }) => {
  const [number, setNumber] = useState(1);

  const getCobrandCardInfo = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const { cobrand_card_type: cardType } = backupUser;
      if (cardType === 'ENTRY') {
        setNumber(2);
      }
      if (cardType === 'PLUS') {
        setNumber(1);
      }
    }
  };

  useEffect(() => getCobrandCardInfo(), [customer]);
  return (
    <div className="box-swal box--modal9" data-info={number}>
      <div className="row">
        <div className="box-modal-cols col-xl-9 col-md-10 col-sm-12 col-12 mx-auto">
          <section className="inner-card">
            <article>
              <div>
                {number === 1 && (
                  <p className="text-green300 h2">Disfrutaste</p>
                )}
                {number === 2 && <p className="text-green300 h2">Estrenaste</p>}
                {number === 1 && (
                  <h2 className="text-green700 h2 mid-title">
                    de los beneficios de tu Tarjeta de Crédito HSBC VIVA.
                  </h2>
                )}
                {number === 2 && (
                  <h2 className="text-green700 h2 mid-title">
                    tu Tarjeta de Crédito HSBC VIVA y empezaste a disfrutar de
                    sus beneficios en vuelos.
                  </h2>
                )}
                {number === 1 && (
                  <p className="text-green700 h2 font-300 ">
                    ¡Y acumulaste más Puntos Doters!
                  </p>
                )}
              </div>
            </article>
            <figure className="tdc-items">
              <img src="../../../assets/images/elements/cards.png" />
            </figure>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Modal9Component;
