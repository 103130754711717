import React from 'react';
// import Lottie from 'react-lottie';
import { ProfileBase } from '../../types/vivaaerobus';


type Props = {
 data: ProfileBase;
};
const ShareComponent = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
 
  const { titleShare,flightTitle,flightValue, secondTitleShare, secondTitleValueShare ,footerTitle, favoriteDestination, profileImage} = data;

  return (
    <div className="share-component" ref={ref} style={{ fontFamily: 'Poppins, sans-serif' }}>
      <div className="lottie-container-share">
        {/* <Lottie options={animationOne} /> */}
        <img src={profileImage} alt="perfil de viva"/>
      </div>
      <div className="body-container">
        <div className="header-share">
        <p className="text-white h3 info-title text-center">{titleShare}</p>
        </div>
        <div className="text-container-primary">
          <div className="content-text w-50">
            <p className="text-primary-content">{flightTitle}</p>
            <p className="text-secondary-content">{flightValue}</p>
          </div>
          <div className="content-text w-50">
            <p className="text-primary-content">{secondTitleShare}</p>
            <p className="text-secondary-content">{secondTitleValueShare}</p>
          </div>
        </div>
        <div className="content-text">
          <p className="text-primary-content">{footerTitle}</p>
          <p className="text-secondary-content">{favoriteDestination}</p>
        </div>
      </div>
      <figure className="footer-share-component">
        <img src="/assets/images/logo/Logo.png" alt="logo viva"/>
      </figure>
    </div>
  );
});

ShareComponent.displayName = 'ShareComponent';

export default ShareComponent;