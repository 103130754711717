import React, { useEffect } from 'react';

const PreventZoom: React.FC = () => {
  useEffect(() => {
    const preventZoom = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && (e.key === '+' || e.key === '-' || e.key === '0')) {
        e.preventDefault();
      }
    };

    const preventWheelZoom = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', preventZoom);
    window.addEventListener('wheel', preventWheelZoom);

    return () => {
      window.removeEventListener('keydown', preventZoom);
      window.removeEventListener('wheel', preventWheelZoom);
    };
  }, []);

  return null; // Componente auxiliar para evitar zoom
};

export default PreventZoom;