import React from 'react';

type Props = {
  number?: number;
};

const Box7Component: React.FC<Props> = ({ number }) => {
  return (
    <div className="item-box item-box--7">
      <div className="item-box__number">{number}</div>
      <figure>
        <img src="/assets/images/grid/square-triangle.svg" alt="Casilla 7" />
      </figure>
    </div>
  );
};

export default Box7Component;
