import React from 'react';

type Props = {
  number?: number;
};

const Box9Component: React.FC<Props> = ({ number }) => {
  return (
    <div className="item-box item-box--9">
      <div className="item-box__number">{number}</div>
      <figure>
        <img
          src="/assets/images/grid/patten-back-vertical.svg"
          alt="Casilla 9"
        />
      </figure>
    </div>
  );
};

export default Box9Component;
