import { userFlags, UserInformation } from "../types/vivaaerobus";


const getCustomerFlags = (customer:Partial<UserInformation>) => {

  const flags: userFlags = {
    membershipInfo:false,
    baggagesInfo:false,
    vivaFanInfo:false,
    cobrandCardInfo:false,
    dotersTickets:false,
    smileFlightCount:false
  }
  const {backupUser} = customer;
  if (backupUser && Object.keys(backupUser).length) {
    const {
      membership_doters_class: doterClass,
      benefits_used_doters_points_acumulation: doterAcumulation,
      checked_baggages_count: baggagesCount,
      membership_vivafan_num: vivafanCount,
      cobrand_card_type: cardType,
      doters_tickets: dotersTickets,
      smile_and_flight_count: flightCount,
    } = backupUser;
    flags.membershipInfo = !!(doterClass && doterClass !== '' &&
      doterAcumulation !== '0');
    flags.baggagesInfo = !!(
      baggagesCount && 
      baggagesCount !== '' &&
      baggagesCount !== '0'
    );
    flags.vivaFanInfo = !!(
      vivafanCount && 
      vivafanCount !== '' &&
      vivafanCount !== '0'
    );
    flags.cobrandCardInfo = !!(cardType && cardType !== '');
    flags.dotersTickets = !!(
      dotersTickets && 
      dotersTickets !== '' &&
      dotersTickets !== '0'
    );
    flags.smileFlightCount = !!(
      flightCount && 
      flightCount !== '' &&
      flightCount !== '0'
    );
  }

return flags;
}
export default {getCustomerFlags}