import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/08-VIVA_EOY24-VivaFan.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  customer: Partial<UserInformation>;
}

const Modal8Component: React.FC<Props> = ({ customer }) => {
  const [veces, setVeces] = useState(0);

  const getVivafanInfo = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const { membership_vivafan_num } = backupUser;
      setVeces(Number(membership_vivafan_num) || 0);
    }
  };

  useEffect(() => getVivafanInfo(), [customer]);
  return (
    <div className="box-swal box--modal8">
      <div className="row">
        <div className="box-modal-cols col-xl-9 col-md-10 col-sm-12 col-12 mx-auto">
          <section className="inner-card">
            <article>
              <div>
                <p className="text-green700 h2 font-300">Ahorraste volando</p>
                <h2 className="text-96 text-green300">{`${veces} ${
                  veces === 1 ? 'vez' : 'veces'
                }`}</h2>
                <p className="text-green700 h2 mid-title">
                  con tu membresía Viva Fan
                </p>
                <p className="text-green700 h2 font-300 ">
                  ¡Ser Fan tiene muchas ventajas!
                </p>
              </div>
            </article>
            <div className="lottie-player">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Modal8Component;
