import React from 'react';

type Props = {
  number?: number;
};

const Box6Component: React.FC<Props> = ({ number }) => {
  return (
    <div className="item-box item-box--6">
            <img src="/assets/images/grid/dot.svg" alt="Puntos" className="dot2" />

      <div className="item-box__number">{number}</div>
      <figure>
        <img src="/assets/images/grid/routes.svg" alt="Rutas" />
      </figure>
      <img src="/assets/images/grid/dot.svg" alt="Puntos" className="dot" />
    </div>
  );
};

export default Box6Component;
