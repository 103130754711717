import React, {useState, useEffect} from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/Backup04-VIVA_EOY24-Minutos-DESKTOP.json';
import animationTab from '../../lotties/Backup04-VIVA_EOY24-Minutos-MOBILE.json';
import animationMobile from '../../lotties/Backup04-VIVA_EOY24-Minutos-TAB_P.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: string;
  customer:Partial<UserInformation>;
}

const ModalTFComponent: React.FC<Props> = ({customer, number = '1' }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallMobile, setIsSmallMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        const width = window.innerWidth;
        setIsMobile(width < 768); // Pantallas tipo "tablet"
        setIsSmallMobile(width <= 431); // Pantallas móviles pequeñas
      }
    };

    handleResize(); // Establecer el valor inicial

    window.addEventListener('resize', handleResize);

    // Limpieza al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const animationMap: { [key: number]: { desktop: any; tablet: any; mobile: any } } = {
    1: {
      desktop: animation,
      tablet: animationTab,
      mobile: animationMobile,
    }
  };


 // Seleccionar la animación adecuada
 const animationData =
 number && animationMap[parseInt(number)]
   ? isSmallMobile
     ? animationMap[parseInt(number)].mobile
     : isMobile
     ? animationMap[parseInt(number)].tablet
     : animationMap[parseInt(number)].desktop
   : animation; // Valor predeterminado

 const animationOptions = {
   loop: false,
   autoplay: true,
   animationData: animationData,
   rendererSettings: {
     preserveAspectRatio: 'xMidYMid meet',
   },
 };

 //Obtener el tiempo de vuelo

 const getTimeFlown = (): string => {
  const timeFlown = customer?.timeFlown;
  return timeFlown ? timeFlown.toLocaleString() : "0";
};



  return (
    <div className="box-swal box--modal1" data-extra={'true'}>
      <div className="row">
        <aside className="box-modal-cols col--lottie col-md-5 col-sm-12 col-12">
          <div className="lottie-player">
            <Lottie options={animationOptions} />
          </div>
        </aside>
        <div className="box-modal-cols col-vertical-center col-md-7 col-sm-12 col-12">
          <article className="box-modal-article">
            <p className="text-white h2 font-300">En total, pasaste</p>
            <h2 className="text-120 text-green300">{getTimeFlown()}</h2>
            <p className="text-white h3 box-modal-article__priority">minutos en el aire.</p>
            <p className="text-white h3 font-300">¿Cuánto pasarás el próximo año?</p>
          </article>
        </div>
      </div>
      
     
    </div>
  );
};

export default ModalTFComponent;