import React from 'react';

type Props = {
  number?: number;
};

const Box1Component: React.FC<Props> = ({ number }) => {
  return (
    <div className="item-box item-box--1">
      <div className="item-box__number">{number}</div>
      <figure>
        <img src="/assets/images/grid/ventana.svg" alt="Ventana" />
      </figure>
    </div>
  );
};

export default Box1Component;
