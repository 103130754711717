import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationA from '../../lotties/02-VIVA_EOY24-Destino_A.json';
import animationB from '../../lotties/02-VIVA_EOY24-Destino_B.json';
import animationC from '../../lotties/02-VIVA_EOY24-Destino_C.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  customer: Partial<UserInformation>;
}

const Modal2Component: React.FC<Props> = ({ customer }) => {
  const [modalInfo, setModalInfo] = useState({
    message: '',
    name: '',
    animation: animationA,
    number: 1,
  });

  const getDestinationInfo = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const { destinations } = backupUser;
      if (destinations && destinations.length) {
        const destination = destinations[0];
        const { name, type } = destination;
        let animation: any = animationA;
        let message = '';
        let number = 1;
        switch (type) {
          case 'Playa': {
            animation = animationA;
            message = 'Disfrutaste del calor de';
            number = 1;
            break;
          }
          case 'US Amigos y Familia':
          case 'Caribe':
          case 'Latinoamérica': {
            animation = animationB;
            message = 'Te paseaste por';
            number = 2;
            break;
          }
          default: {
            animation = animationC;
            message = 'Te diste la vuelta por';
            number = 3;
            break;
          }
        }
        console.log({ animation });
        setModalInfo({ message, name, animation, number });
        return;
      }
    }
    setModalInfo({
      message: '',
      name: '',
      animation: animationA,
      number: 1,
    });
    return;
  };

  useEffect(() => getDestinationInfo(), [customer]);

  return (
    <div className="box-swal box--modal2" data-number={modalInfo.number}>
      <div className="row">
        <div className="col-12">
          <section className="ticket">
            <article className="fade-in">
              <p className="text-green700 h3">{modalInfo.message}</p>
              <h2 className="text-green500">{modalInfo.name}</h2>
            </article>
            <div className="lottie-player">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: modalInfo.animation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Modal2Component;
